import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { H4, H6 } from '@luigiclaudio/ga-baseline-ui/typography';
import { ProgressiveBg } from '@luigiclaudio/ga-baseline-ui/helpers';
import ProductItemContainer from './ProductItemContainer';
import ProductItemLink from './ProductItemLink';
import ProductItemImage from './ProductItemImage';
import ProductItemPriceContainer from './ProductItemPriceContainer';
import ProductItemDetails from './ProductItemDetails';
import ProductItemHeader from './ProductItemHeader';
import ProductItemImageContainer from './ProductItemImageContainer';
import ProductItemImagePlaceholder from './ProductItemImagePlaceholder';
import ProductItemDiscountedPriceLabel from './ProductItemDiscountedPriceLabel';
import brandUtils from '../../../utils/brandUtils';
import BrandContext from '../../context/BrandContext';
import ProdcutItemSmall from './ProdcutItemSmall';
import ProductTitle from './ProductTitle';

const ProductItem = ({
    brand,
    url,
    image,
    title,
    price,
    retailer,
    priceBeforeSale,
    currency,
    itemWidth,
    itemHeight,
    themeMode,
}) => {
    const { brandDetailsOnStage, productOnStage, setProductOnStage } = useContext(BrandContext);
    const { brand: brandOnStage } = brandDetailsOnStage;

    const priceFormatOptions = brandUtils.priceFormatOptions(currency);

    const renderPrice = !price ? (
        <H4>
            <FormattedNumber value={priceBeforeSale} {...priceFormatOptions} />
        </H4>
    ) : (
        <>
            <H4>
                <FormattedNumber value={price} {...priceFormatOptions} />
            </H4>
            <H6 color="muted">
                <ProductItemDiscountedPriceLabel>
                    <FormattedNumber value={priceBeforeSale} {...priceFormatOptions} />
                </ProductItemDiscountedPriceLabel>
            </H6>
        </>
    );

    const imageBoxShadow = `inset 0 0 24px 0px rgb(0 0 0 / ${
        themeMode === 'lightMode' ? '8%' : '32%'
    })`;

    return (
        <ProductItemContainer itemWidth={itemWidth} itemHeight={itemHeight}>
            <ProductItemLink
                to={`/showProduct?brand=${brandUtils.getBrandSlug(brand)}&url=${url}`}
                onClick={() => {
                    if (productOnStage.url !== url) {
                        setProductOnStage(undefined);
                    }
                }}
            >
                <ProductItemHeader>
                    <ProductItemImage hasBrand={brand !== brandOnStage}>
                        <ProductItemImageContainer>
                            <ProgressiveBg image={image} style={{ boxShadow: imageBoxShadow }} />
                        </ProductItemImageContainer>
                        <ProductItemImagePlaceholder />
                    </ProductItemImage>
                    {brand !== brandOnStage && (
                        <ProductItemDetails>
                            <ProductItemPriceContainer>
                                <ProdcutItemSmall colorValue="dark">{brand}</ProdcutItemSmall>
                            </ProductItemPriceContainer>
                        </ProductItemDetails>
                    )}
                    <ProductTitle>{title}</ProductTitle>
                </ProductItemHeader>
                <ProductItemDetails>
                    <ProductItemPriceContainer>{renderPrice}</ProductItemPriceContainer>
                </ProductItemDetails>
                <ProductItemDetails>
                    <ProductItemPriceContainer>
                        <ProdcutItemSmall colorValue="muted">
                            <FormattedMessage id={`retailer.${retailer}`} />
                        </ProdcutItemSmall>
                    </ProductItemPriceContainer>
                </ProductItemDetails>
            </ProductItemLink>
        </ProductItemContainer>
    );
};

ProductItem.propTypes = {
    brand: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    price: PropTypes.number,
    retailer: PropTypes.string.isRequired,
    priceBeforeSale: PropTypes.number,
    currency: PropTypes.string.isRequired,
    itemWidth: PropTypes.number.isRequired,
    itemHeight: PropTypes.number.isRequired,
    themeMode: PropTypes.string.isRequired,
};

ProductItem.defaultProps = {
    image: undefined,
    price: undefined,
    priceBeforeSale: undefined,
};

export default ProductItem;
