import styled from 'styled-components';

const ProductItemHeader = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
`;

export default ProductItemHeader;
