import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { Loader, ProgressiveBg, SrLabel } from '@luigiclaudio/ga-baseline-ui/helpers';
import { isSizes } from '@luigiclaudio/ga-baseline-ui/layout';
import { GatsbyA } from '@luigiclaudio/ga-baseline-ui/typography';
import categoryUtils from '../../../utils/categoryUtils';
import IntroProductsFeatureItemContainer from './IntroProductsFeatureItemContainer';
import IntroProductsFeatureItemsContainer from './IntroProductsFeatureItemsContainer';
import brandUtils from '../../../utils/brandUtils';
import BrandContext from '../../context/BrandContext';

const IntroProductsFeatureAside = ({ category, isXl, isLg, isMd, isSm, isXs, isXxs }) => {
    const { setProductOnStage } = useContext(BrandContext);
    const [productsCategory, setProductsCategory] = useState();
    let minProducts = 8;
    let maxProducts = 12;

    if (isXl) {
        minProducts = 4;
        maxProducts = 6;
    }

    if (isXl && isLg) {
        minProducts = 2;
        maxProducts = 2;
    }

    if (isXl && isLg && isMd) {
        minProducts = 2;
        maxProducts = 2;
    }

    if (isXl && isLg && isMd && isSm) {
        minProducts = 4;
        maxProducts = 4;
    }

    if (isXl && isLg && isMd && isSm && isXs) {
        minProducts = 2;
        maxProducts = 2;
    }

    if (isXl && isLg && isMd && isSm && isXs && isXxs) {
        minProducts = 2;
        maxProducts = 2;
    }

    useEffect(() => {
        if (category) {
            const getCategory = async () => {
                const response = await categoryUtils.categoryApi(category);
                setProductsCategory(response);
            };
            getCategory();
        }
    }, [category]);

    if (!productsCategory) {
        return <Loader size={40} />;
    }

    const { products } = productsCategory;

    let sliceMaxProductsStart = products.length;

    if (products.length - maxProducts > maxProducts) {
        sliceMaxProductsStart = products.length - maxProducts;
    }

    const sliceProductsStart = Math.floor(Math.random() * (sliceMaxProductsStart - 0) + 0);

    const sliceProductsEnd = Math.floor(
        Math.random() * (sliceProductsStart + maxProducts - (sliceProductsStart + minProducts)) +
            (sliceProductsStart + minProducts),
    );

    const slicedProducts = products.slice(sliceProductsStart, sliceProductsEnd);
    const evenSlicedProducts = slicedProducts.slice(0, 2 * Math.floor(slicedProducts.length / 2));

    const renderProducts = evenSlicedProducts.map((product, index) => {
        const { image, brand, url, title } = product;
        const renderTo = `/showProduct?brand=${brandUtils.getBrandSlug(brand)}&url=${url}`;

        return (
            <IntroProductsFeatureItemContainer
                key={index}
                itemsRowAmount={evenSlicedProducts.length / 2}
            >
                <GatsbyA
                    to={renderTo}
                    onClick={() => {
                        setProductOnStage(product);
                    }}
                >
                    <SrLabel as="span">{title}</SrLabel>
                    <ProgressiveBg image={image} />
                </GatsbyA>
            </IntroProductsFeatureItemContainer>
        );
    });

    return (
        <IntroProductsFeatureItemsContainer>{renderProducts}</IntroProductsFeatureItemsContainer>
    );
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isXl: isSizes.xlView({ width }, { theme }),
        isLg: isSizes.lgView({ width }, { theme }),
        isMd: isSizes.mdView({ width }, { theme }),
        isSm: isSizes.smView({ width }, { theme }),
        isXs: isSizes.xsView({ width }, { theme }),
        isXxs: isSizes.xxsView({ width }, { theme }),
    };
};

IntroProductsFeatureAside.propTypes = {
    category: PropTypes.string,
    isXl: PropTypes.bool,
    isLg: PropTypes.bool,
    isMd: PropTypes.bool,
    isSm: PropTypes.bool,
    isXs: PropTypes.bool,
    isXxs: PropTypes.bool,
};

IntroProductsFeatureAside.defaultProps = {
    category: undefined,
    isXl: false,
    isLg: false,
    isMd: false,
    isSm: false,
    isXs: false,
    isXxs: false,
};

export default withSizes(mapSizesToProps)(IntroProductsFeatureAside);
