import styled from 'styled-components';
import { rem } from 'polished';

const IntroProductsFeatureItemContainer = styled.div`
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
    height: calc(50% - ${rem(8 * 2)});
    margin: ${rem(8)};
    overflow: hidden;
    width: ${({ itemsRowAmount }) => `calc(100% / ${itemsRowAmount} - ${rem(8 * 2)})`};
`;

export default IntroProductsFeatureItemContainer;
