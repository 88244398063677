import React, { useState, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHasMounted, TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import { SiteLaunchTemplate } from '@luigiclaudio/ga-baseline-ui/site-launch';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import SiteFooter from '../components/pageLayout/SiteFooter';
import MailingListSubscribeFeature from '../components/pageLayout/MailingListSubscribeFeature';
import IntroCustomBg from '../components/marketing/IntroCustomBg';
import marketingUtils from '../utils/marketingUtils';
import IntroProductItem from '../components/marketing/IntroProductItem';
import brandUtils from '../utils/brandUtils';
import appConfig from '../data/appConfig.json';
import IntroProductsFeatureList from '../components/marketing/IntroProductsFeatureList';
import BrandContext from '../components/context/BrandContext';

const Index = () => {
    const intl = useIntl();
    const hasMounted = useHasMounted();
    const { productOnStage } = useContext(BrandContext);
    const { themeMode } = useContext(ThemeModeContext);
    const [introProductsApi, setIntroProductsApi] = useState();
    const { countries } = appConfig;

    let isDarkModeOn = false;

    useEffect(() => {
        if (themeMode === 'darkMode') {
            isDarkModeOn = true;
        }
    }, [themeMode]);

    useEffect(() => {
        const getMarketingProducts = async () => {
            const response = await marketingUtils.introProductsApi();
            setIntroProductsApi(response);
        };
        getMarketingProducts();
    }, []);

    if (!hasMounted || !introProductsApi) {
        return null;
    }

    const viewportHeight = 69;
    const renderProduct = introProductsApi[Math.floor(Math.random() * introProductsApi.length)];
    const { brand } = renderProduct;

    const renderBrandOnStage = productOnStage?.brand || brand;
    const renderProductOnStage = productOnStage || renderProduct;

    return (
        <SiteLaunchTemplate
            title={intl.formatMessage({ id: 'home.intro.title' })}
            subtitle={intl.formatMessage({ id: 'home.intro.subtitle' })}
            callToActions={[
                {
                    type: 'link',
                    label: intl.formatMessage(
                        {
                            id: 'home.intro.callToAction.viewBrand.label',
                        },
                        {
                            brand: renderBrandOnStage,
                        },
                    ),
                    size: 'lg',
                    btnType: 'outlined',
                    skin: 'primary',
                    path: `/${brandUtils.getPathToBrand(
                        productOnStage?.brand || brand,
                        countries[0],
                    )}`,
                },
            ]}
            customBg={
                <IntroCustomBg
                    product={renderProductOnStage}
                    viewportHeight={viewportHeight}
                    themeMode={{ themeMode }}
                />
            }
            viewportHeight={viewportHeight}
            valuePropositionColor={isDarkModeOn ? 'light' : 'dark'}
            alignMainItems="center"
            aside={<IntroProductItem product={renderProductOnStage} />}
            content={
                <TransitionItem inProp appear isFullHeight transitionTimeout={{ enter: 850 }}>
                    <IntroProductsFeatureList />
                    <MailingListSubscribeFeature />
                </TransitionItem>
            }
            footer={<SiteFooter />}
        />
    );
};

export default Index;
