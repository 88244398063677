import styled from 'styled-components';
import { rem } from 'polished';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';

const ProductItemContainer = styled.div`
    align-items: center;
    display: flex;
    flex-basis: ${({ itemWidth }) => rem(itemWidth)};
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    justify-content: center;
    margin: 0 ${({ theme }) => rem(theme.site.productItem.oneColumn.gapH)};

    ${media.sm`
        height: 100%;
        margin: 0 ${({ theme }) => rem(theme.site.productItem.multiColumn.gapH)};
    `}
`;

export default ProductItemContainer;
