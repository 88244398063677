import styled from 'styled-components';

const ProductItemDetails = styled.div`
    align-items: flex-end;
    display: flex;
    flex: 0;
    height: 100%;
`;

export default ProductItemDetails;
