import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import withSizes from 'react-sizes';
import { ProductFeatureList } from '@luigiclaudio/ga-baseline-ui/commerce';
import { isSizes } from '@luigiclaudio/ga-baseline-ui/layout';
import categoryList from '../../../data/categoryList.json';
import categoryShortList from './categoryShortList.json';
import IntroProductsFeatureAside from './IntroProductsFeatureAside';
import CategoryTitle from '../../app/CategoryTitle';
import categoryUtils from '../../../utils/categoryUtils';
import appConfig from '../../../data/appConfig.json';

const IntroProductsFeatureList = ({ isLg, isMd, isSm }) => {
    const intl = useIntl();
    const featureList = [];
    const { countries } = appConfig;

    let descriptionHorizontalSpacing = 80;

    if (isLg) {
        descriptionHorizontalSpacing = 0;
    }

    if (!isMd && isLg) {
        descriptionHorizontalSpacing = 40;
    }

    if (!isSm && isMd && isLg) {
        descriptionHorizontalSpacing = 20;
    }

    categoryList.forEach((category) => {
        const { title } = category;

        if (categoryShortList.some((item) => item === title)) {
            const pathToCategory = `/${categoryUtils.getPathToCategory(title, countries[0])}`;
            const categorySlug = categoryUtils.getCategorySlug(title);

            featureList.push({
                title: <CategoryTitle>{title}</CategoryTitle>,
                ctaLabel: intl.formatMessage(
                    {
                        id: 'home.productsFeatureList.viewAllCategories.label',
                    },
                    {
                        category: title,
                    },
                ),
                to: pathToCategory,
                ctaBtn: { btnType: 'outlined', skin: 'primary' },
                aside: {
                    composition: (
                        <>
                            <Helmet>
                                <link
                                    rel="prefetch"
                                    href={`/api/categories/category-${categorySlug}.json`}
                                    as="fetch"
                                />
                            </Helmet>
                            <IntroProductsFeatureAside category={title} isSm={isSm} />
                        </>
                    ),
                },
                alignItems: 'center',
                isFullWidth: true,
                descriptionColMedia: {
                    xl: 12,
                },
                assetColMedia: {
                    xl: 15,
                },
                descriptionHorizontalSpacing,
            });
        }
    });

    return <ProductFeatureList list={featureList} alternatePosition />;
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isLg: isSizes.lgView({ width }, { theme }),
        isMd: isSizes.mdView({ width }, { theme }),
        isSm: isSizes.smView({ width }, { theme }),
    };
};

IntroProductsFeatureList.propTypes = {
    isLg: PropTypes.bool,
    isMd: PropTypes.bool,
    isSm: PropTypes.bool,
};

IntroProductsFeatureList.defaultProps = {
    isLg: false,
    isMd: false,
    isSm: false,
};

export default withSizes(mapSizesToProps)(IntroProductsFeatureList);
