import styled from 'styled-components';
import { rem } from 'polished';

const ProductItemImage = styled.div`
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
    flex: 1;
    margin-bottom: ${({ hasBrand }) => rem(hasBrand ? 8 : 4)};
    overflow: hidden;
    position: relative;
    width: ${({ theme }) => theme.site.productItem.image.width};
`;

export default ProductItemImage;
